<template>
    <div id="contenu" class="page-wrapper page-contenu">
        <!-- SubHero -->
        <header class="sub-hero site-max-width" hero-transition-group data-gsap-change-color="default">
            <div class="container">
                <h1 class="small-title title" data-inview="fadeInUp" data-delay="100">Contenu</h1>
                <p class="big-text text" data-inview="fadeInUp" data-delay="200" data-gsap-change-color="default">
                    On a les idées qui débordent <br />
                    du contenant.
                </p>
                <img src="@/assets/imgs/contenu-hero.jpg" alt="Contenu" class="main" data-inview="fadeInUp" data-delay="500" data-gsap-change-color="default" />
            </div>
        </header>

        <!-- Content -->
        <div
            id="zone-methodologie"
            class="content-text bomb-anim no-border site-max-width"
            :class="{ 'is-close': lottieAnimDone }"
            data-bg-3
            data-gsap-play-lottie
            data-gsap-change-color="colorAccent3"
        >
            <div class="container">
                <div class="spacer no-border" data-gsap-change-color="colorAccent3"></div>
                <div class="bomb">
                    <div class="left">
                        <p class="big-text">
                            Du contenu <br />
                            qui menace de <br />
                            faire exploser <br />
                            l’internet.
                        </p>
                    </div>
                    <div class="right">
                        <img class="lottie-ph" src="@/assets/imgs/bomb-placeholder.png" alt="" />
                    </div>
                    <lottie-player :src="lottieFile"  background="transparent" id="lottie-player" speed="1" class="lottie-player-file"></lottie-player>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-creativite" class="content-text splits no-border site-max-width" data-bg-4 data-gsap-change-color="colorAccent4" hero-transition-group>
            <div class="spacer"></div>
            <div class="container">
                <article class="split">
                    <div class="content">
                        <h4 class="medium-text" data-inview="fadeInUp" data-delay="200">
                            On gère le contenu de 350  campagnes, en 360, et ce, 365 jours par année.
                        </h4>
                    </div>
                    <div class="visual" data-inview="revealRight" data-color="colorAccent4" data-delay="200">
                        <img src="@/assets/imgs/campagnes-360.jpg" alt="Capagnes 360" />
                    </div>
                </article>
                <article class="split reverse">
                    <div class="content">
                        <h4 class="medium-text" data-inview="fadeInUp" data-delay="200">
                            Les balados sont « the next big thing »? On en a créé plus d’une centaine.
                        </h4>
                    </div>
                    <div class="visual" data-inview="revealRight" data-color="colorAccent4" data-delay="200">
                        <img src="@/assets/imgs/balados.jpg" alt="Balados" />
                    </div>
                </article>
                <article class="split">
                    <div class="content">
                        <h4 class="medium-text" data-inview="fadeInUp" data-delay="200">
                            On a réalisé plus de 100 vidéos Web. Une carrière TikTok, c’est bel et bien possible.
                        </h4>
                    </div>
                    <div class="visual" data-inview="revealLeft" data-color="colorAccent4" data-delay="200">
                        <img src="@/assets/imgs/videos.jpg" alt="Vidéos" />
                    </div>
                </article>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-creativite" class="content-text splits no-border site-max-width" data-bg-3 data-gsap-change-color="colorAccent3" hero-transition-group>
            <div class="container" data-gsap-change-color="colorAccent3">
                <div class="top">
                    <h2 class="medium-title title">
                        <span data-inview="fadeInUp" data-delay="100">Tout est contenu avec nous.</span> <br />
                        <span data-inview="fadeInUp" data-delay="200">Tout est possible avec nous.</span>
                    </h2>
                </div>
                <div class="lone-img" data-gsap-change-color="colorAccent3">
                    <div data-inview="revealLeft" data-color="colorAccent3" data-delay="100"><img src="@/assets/imgs/contenu-reel.jpg" alt="Sortir du cadre" /></div>

                    <div class="content">
                        <a href="https://vimeo.com/706659625" class="glightbox" >
                            <h2 class="video-title big-title title" data-inview="fadeInUp" data-delay="100">
                                Voyez-nous
                                sortir du cadre.
                                <br />
                                <svg
                                    class="play-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="75.347"
                                    height="75.348"
                                    viewBox="0 0.026 75.347 75.348"
                                    data-inview="fadeInUp" data-delay="200"
                                >
                                    <g>
                                        <path
                                            d="M30.733 56.192V18.54c0-.983.591-1.868 1.499-2.244a2.427 2.427 0 0 1 .788-.18c.047-.003.094-.005.14-.005a2.424 2.424 0 0 1 1.718.712L53.704 35.65a2.416 2.416 0 0 1 .664 1.239 2.425 2.425 0 0 1-.664 2.195L34.878 57.91a2.428 2.428 0 0 1-1.718.712 2.414 2.414 0 0 1-.163-.006 2.423 2.423 0 0 1-.765-.18 2.428 2.428 0 0 1-1.5-2.243Zm4.857-31.789v25.925l12.962-12.962L35.59 24.403Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 14"
                                        />
                                        <path
                                            d="M37.674-.474c20.85 0 37.673 16.824 37.673 37.674a37.553 37.553 0 0 1-37.673 37.674A37.483 37.483 0 0 1 .004 37.813 39.296 39.296 0 0 1 0 37.2 37.554 37.554 0 0 1 37.674-.474Zm0 70.496c18.063 0 32.72-14.655 32.72-32.822 0-18.063-14.657-32.72-32.72-32.72C19.51 4.48 4.851 19.137 4.851 37.2a32.773 32.773 0 0 0 32.823 32.822Z"
                                            fill="#ceffb4"
                                            fill-rule="evenodd"
                                            data-name="Path 13"
                                        />
                                    </g>
                                </svg>
                            </h2>
                        </a>
                    </div>
                </div>
                <div class="testimonial padding-top">
                    <p class="big-text" data-inview="fadeInUp" data-delay="100">
                        « Multiplateforme… C’est plus large qu’on pense. Et non, il ne suffit pas de reprendre le visuel d’abribus pour une publication Instagram. »
                    </p>
                    <p class="small-text" data-inview="fadeInUp" data-delay="200">— Nos experts en stratégie et création de contenu.</p>
                </div>
                <article class="top-border">
                    <div class="cols">
                        <div class="left">
                            <h3 class="small-title" data-inview="fadeInUp" data-delay="100">Le contenu</h3>
                        </div>
                        <div class="right">
                            <p class="small-text right-text" data-inview="fadeInUp" data-delay="200">
                                Après des années d’expérience, de développement et d’écoute, nous sommes devenus des experts en stratégie et en création de contenu. On a appris à connaître la valeur, la portée et l’importance de rejoindre les gens, partout où ils regardent. Et on a compris qu’aujourd’hui, ils regardent leur tamagotchi plus souvent qu’autrement.
                                <br/><br/>
                                Le meilleur moyen de rejoindre la cible est donc de s’insérer dans ce qu’elle consomme au quotidien, et de lui montrer exactement ce qu’elle veut voir. Avoir une présence dans les médias traditionnels, mais aussi viser juste avec du contenu numérique adapté, pertinent et innovant. Être omnicanal. En d’autres mots : être partout.
                            </p>
                        </div>
                    </div>

                    <div class="scroll-words">
                        <div class="scroller" data-gsap-scroll-left>
                            <div class="auto-track">
                                <p class="word">Instagram&nbsp;live</p>
                                <p class="word">websérie</p>
                                <p class="word">Balados</p>
                                <p class="word">TikTok</p>
                                <p class="word">Articles</p>
                                <p class="word">Facebook</p>
                                <p class="word">Blogue</p>
                                <p class="word">Vidéo</p>
                                <p class="word">Natif</p>
                                <p class="word">Infolettres</p>
                                <p class="word">Influenceurs</p>

                                <p class="word">Instagram&nbsp;live</p>
                                <p class="word">websérie</p>
                                <p class="word">Balados</p>
                                <p class="word">TikTok</p>
                                <p class="word">Articles</p>
                                <p class="word">Facebook</p>
                                <p class="word">Blogue</p>
                                <p class="word">Vidéo</p>
                                <p class="word">Natif</p>
                                <p class="word">Infolettres</p>
                                <p class="word">Influenceurs</p>

                                <p class="word">Instagram&nbsp;live</p>
                                <p class="word">websérie</p>
                                <p class="word">Balados</p>
                                <p class="word">TikTok</p>
                                <p class="word">Articles</p>
                                <p class="word">Facebook</p>
                                <p class="word">Blogue</p>
                                <p class="word">Vidéo</p>
                                <p class="word">Natif</p>
                                <p class="word">Infolettres</p>
                                <p class="word">Influenceurs</p>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="top-border" data-gsap-change-color="colorAccent3">
                    <div class="cols">
                        <div class="left">
                            <h3 class="small-title" data-inview="fadeInUp" data-delay="100">La méthodologie</h3>
                        </div>
                        <div class="right">
                            <p class="small-text right-text" data-inview="fadeInUp" data-delay="200">
                                Chez Qolab, notre mission est d’attirer l’attention et de mobiliser les audiences en les fidélisant encore et toujours plus. Pour y arriver, nous vous recommandons les meilleurs choix de plateformes, de partenaires et de tactiques. Et tout ça, dans un esprit d’agilité et d’adaptation pour une approche multiplateforme sans faille.
                                <br /><br />
                                <strong class="focus">
                                    Vigie, stratégie, planification, <br/> exécution, amplification, mesure, rétroaction… Ce ne sont pas que des buzzwords, c’est notre méthode de travail.
                                </strong>
                                <br /><br />
                                Est-ce qu’on vous a dit qu’on <em>multitask</em>? Ensemble, nos experts en contenu élaborent des stratégies de contenu distinctives et pertinentes pour les marques, créent et déploient des contenus multiplateformes en s’appuyant sur l’ensemble de nos ressources et animent les réseaux sociaux comme si leur vie en dépendait. Et ils optimisent tout ça 24/7 en vue d’obtenir les meilleurs résultats.
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'Production-fr' }" class="full-video footer-nav" data-gsap-activate-footer data-gsap-change-color="colorAccent3">
            <div class="container" data-gsap-change-color="colorAccent3">
                <img class="main" src="@/assets/imgs/contenu-footer-nav.jpg" alt="Production" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Quand on a une idée, on la fait. Découvrez notre machine de production.

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import * as LottiePlayer from "@lottiefiles/lottie-player";

import GLightbox from 'glightbox'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Contenu',

    components: {
        Footer,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.lottieRef = LottiePlayer
                    this.lottieElement = document.querySelector('#lottie-player')

                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        setTimeout(() => {
                            this.initGsap()
                            this.initGsapBgColors()

                            this.gsapActivateFooter()

                            gsap.utils.toArray('[data-gsap-parrallax-scroll]').forEach(element => {
                                gsap.to(element, {
                                    scrollTrigger: {
                                        trigger: element,
                                        start: 'top bottom',
                                        end: 'bottom top',
                                        scrub: 1.475,
                                    },
                                    y: '-25%',
                                    ease: 'none',
                                })
                            })

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            lottieRef: null,
            lottieElement: null,
            lottieAnimDone: false,
            lottieFile: window.matchMedia('(max-width: 599px)').matches ? '/static/bombe-mobile.json' : '/static/bombe.json',
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGsap() {
            gsap.utils.toArray('[data-gsap-scroll-left]').forEach(track => {
                gsap.to(track, {
                    scrollTrigger: {
                        trigger: track,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 1.475,
                    },
                    x: '-25%',
                    ease: 'none',
                })
            })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 45%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-contenu')) {
                            return
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-contenu').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-contenu').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-contenu')) {
                            return
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-contenu').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-contenu').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-contenu')) {
                        return
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-contenu')) {
                        return
                    }
                    footerFold.classList.remove('is-active')
                },
            })

            const triggerPlayLottie = document.querySelector('[data-gsap-play-lottie]')
            ScrollTrigger.create({
                trigger: triggerPlayLottie,
                start: 'top 30%',
                onEnter: () => {
                    if (!document.querySelector('#lottie-player')) {
                        return
                    }

                    document.querySelector('#lottie-player').play()

                    document.querySelector('#lottie-player').addEventListener("complete", () => {
                        document.querySelector('.page-wrapper.page-contenu').classList.add('colorAccent4')
                        this.lottieAnimDone = true
                        setTimeout(() => {
                            ScrollTrigger.refresh()
                        }, 1000);
                    })
                },
            })
        }
    },
}
</script>
